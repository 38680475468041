<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">排查列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 250px" size="small" v-model="search.keyword" placeholder="网格员/网格/场所名称/手机"
						clearable @clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
				</div>
				<div class="d-flex align-items-center m-1">
					<div class="label ">单位：</div>
					<el-select @change="handleFirstChange" v-model="search.first_id" placeholder="请选择支队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleSecondChange" v-model="search.second_id" placeholder="请选择大队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleThirdChange" v-model="search.third_id" placeholder="请请选择街道">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<div class="label ml-3">网格：</div>
					<el-select v-model="search.reseau_id" placeholder="请请选择网格">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in reseauList" :label="item.name" :key="item.reseau_id"
							:value="item.reseau_id">
						</el-option>
					</el-select>
				</div>
				<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search"></el-button>
				<el-button @click="handleExport" class="ml-2" type="primary" size="mini">导出</el-button>
			</div>
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					预警分级：
					<el-button :type="search.early_warning == 0 ? 'primary':''" @click="earlyWarning(0)" size="mini">全部
					</el-button>
					<el-button :type="search.early_warning == 10 ? 'primary':''" @click="earlyWarning(10)" size="mini">
						良好</el-button>
					<el-button :type="search.early_warning == 20 ? 'primary':''" @click="earlyWarning(20)" size="mini">
						一般</el-button>
					<el-button :type="search.early_warning == 30 ? 'primary':''" @click="earlyWarning(30)" size="mini">
						危险</el-button>
					<el-button :type="search.early_warning == 40 ? 'primary':''" @click="earlyWarning(40)" size="mini">
						高危</el-button>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%" :default-expand-all="true">
				<el-table-column type="expand">
					<template slot-scope="scope">
						<div class="ml-4">
							<div class="d-flex align-items-center">
								<div>地址：{{scope.row.address}}</div>
								<div class="ml-3">
									位置定位：{{scope.row.latitude}},{{scope.row.longitude}}
									<el-button size="mini" @click="showMap(scope.row)">展开地图</el-button>
								</div>
							</div>
							<div class="d-flex">
								场所当前状态：
							</div>
							<div class="d-flex flex-wrap">
								<div class="m-0 p-0 d-flex align-items-center">
									是否住人：<span><template v-if="scope.row.is_person == 10"><span
												style="color:red;">否</span></template><template v-else>是 </template>
									</span>
									<template v-if="scope.row.person_image.length > 0">
										&nbsp;&nbsp;&nbsp;住人撤离图片
										<viewer style="padding: 10px;">
											<img style="width: 45px;height: 45px;margin:0 5px;"
												:src="scope.row.person_image">
										</viewer>
									</template>
								</div>
								<div v-if="scope.row.is_person == 20" class="m-0 p-0 d-flex align-items-center ml-3">
									是否撤离：<span><template v-if="scope.row.is_evacuate == 10"><span
												style="color:red;">否</span></template><template v-else>是 </template>
									</span>
								</div>
								<div class="m-0 p-0 d-flex align-items-center ml-3">开设逃生通道：<span><template
											v-if="scope.row.is_escape == 10"><span
												style="color:red;">否</span></template><template
											v-else>是</template></span>
									<template v-if="scope.row.escape_image.length > 0">
										<viewer style="padding: 10px;">
											<img style="width: 45px;height: 45px;margin:0 5px;"
												:src="scope.row.escape_image">
										</viewer>
									</template>
								</div>
								<div class="m-0 p-0 d-flex align-items-center ml-3">落实防火分隔：<span><template
											v-if="scope.row.is_separate == 10"><span
												style="color:red;">否</span></template><template
											v-else>是</template></span>
									<viewer v-if="scope.row.separate_image.length > 0" style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.separate_image">
									</viewer>
								</div>
								<div class="m-0 p-0 d-flex align-items-center ml-3">安装独立烟感：<span><template
											v-if="scope.row.is_smoke == 10"><span
												style="color:red;">否</span></template><template
											v-else>是</template></span>
									<viewer v-if="scope.row.smoke_image.length > 0" style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.smoke_image">
									</viewer>
								</div>
								<div class="m-0 p-0 d-flex align-items-center ml-3">配备防毒面罩：<span><template
											v-if="scope.row.is_defense == 10"><span
												style="color:red;">否</span></template><template
											v-else>是</template></span>
									<viewer v-if="scope.row.defense_image.length > 0" style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.defense_image">
									</viewer>
								</div>
								<div class="m-0 p-0 d-flex align-items-center ml-3">开展宣传培训：<span><template
											v-if="scope.row.is_train == 10"><span
												style="color:red;">否</span></template><template
											v-else>是</template></span>
									<viewer v-if="scope.row.train_image.length > 0" style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.train_image">
									</viewer>
								</div>
								<div class="m-0 p-0 d-flex align-items-center ml-3">电器线路套管铺设：<span><template
											v-if="scope.row.is_circuit == 10"><span
												style="color:red;">否</span></template><template
											v-else>是</template></span>
									<viewer v-if="scope.row.circuit_image.length > 0" style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.circuit_image">
									</viewer>
								</div>
								<div class="m-0 p-0 d-flex align-items-center ml-3">无使用闸刀开关：<span><template
											v-if="scope.row.is_knife == 10"><span
												style="color:red;">否</span></template><template
											v-else>是</template></span>
									<viewer v-if="scope.row.knife_image.length > 0" style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.knife_image">
									</viewer>
								</div>
								<div class="m-0 p-0 d-flex align-items-center ml-3">没有违规存放电动车：<span><template
											v-if="scope.row.is_electrocar == 10"><span
												style="color:red;">否</span></template><template
											v-else>是</template></span>
									<viewer v-if="scope.row.electrocar_image.length > 0" style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.electrocar_image">
									</viewer>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column width="230" align="center" label="网格员/单位用户">
					<template slot-scope="scope">
						<template v-if="scope.row.user_type == 10">
							<span v-if="scope.row.reseau_user" style="font-weight: 800;">{{scope.row.reseau_user.real_name}}
								{{scope.row.reseau_user.mobile}}</span>
						</template>
						<template v-if="scope.row.user_type == 20">
							<span v-if="scope.row.unit_user" style="font-weight: 800;">{{scope.row.unit_user.real_name}}
								{{scope.row.unit_user.mobile}}</span>
						</template>
						<template v-if="scope.row.edit_user_type == 10">
							<span v-if="scope.row.is_edit == 20"
								style="font-weight: 800;"><br>修改人：{{scope.row.edit_reseau_user.real_name}}
								{{scope.row.edit_reseau_user.mobile}}</span>
						</template>
						<template v-if="scope.row.edit_user_type == 20">
							<span v-if="scope.row.is_edit == 20 && scope.row.edit_unit_user != null"
								style="font-weight: 800;"><br>修改人：{{scope.row.edit_unit_user.real_name}}
								{{scope.row.edit_unit_user.mobile}}</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="网格">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.second_unit_name}} - {{scope.row.third_unit_name}} - {{scope.row.reseau.name}} （ID：{{scope.row.reseau_id}}）</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="场所名称">
					<template slot-scope="scope">
						<span>{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column width="135" align="center" label="业主姓名">
					<template slot-scope="scope">
						<span>{{scope.row.real_name}}</span>
					</template>
				</el-table-column>
				<el-table-column width="150" align="center" label="业主手机号">
					<template slot-scope="scope">
						<span>{{scope.row.mobile}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="150" label="预警分级">
					<template slot-scope="scope">
						<div style="width: 100%;" class="d-flex justify-content-center align-items-center">
							<div v-if="scope.row.early_warning == 10"
								style="width: 80px;height: 30px;background-color: rgb(108, 189, 40);line-height: 30px;font-weight: 800;color:#fff;">
								良好
							</div>
							<div v-if="scope.row.early_warning == 20"
								style="width: 80px;height: 30px;background-color: rgb(50,180,237);line-height: 30px;font-weight: 800;color:#fff;">
								一般
							</div>
							<div v-if="scope.row.early_warning == 30"
								style="width: 80px;height: 30px;background-color: rgb(250, 186, 38);line-height: 30px;font-weight: 800;color:#fff;">
								危险
							</div>
							<div v-if="scope.row.early_warning == 40"
								style="width: 80px;height: 30px;background-color: rgb(195,5,33);line-height: 30px;font-weight: 800;color:#fff;">
								高危
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center" width="150" label="整改期限">
					<template slot-scope="scope">
						<span>{{scope.row.deadline_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="150" label="添加时间">
					<template slot-scope="scope">
						<span>{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="200" label="操作">
					<template slot-scope="scope">
						<router-link
							:to="{name:'reseau.check/edit',params: {reseau_check_id:scope.row.reseau_check_id}}">
							<el-button type="text" size="mini" class="table-btn">修改
							</el-button>
						</router-link>
						<el-button type="text" size="mini" class="table-btn" @click="openDialog(scope.row)">整改日志</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<app-map ref="appMap" :lat="latitude" :long="longitude" :address="address"></app-map>
		</div>
		<el-dialog title="修改记录" :visible.sync="dialogVisible" top="3vh" width="80%">
			<el-table border class="mt-3" :data="logLists.data" style="width: 100%" :default-expand-all="true">
				<el-table-column type="expand">
					<template slot-scope="scope">
						<div class="ml-4">
							<div class="d-flex flex-wrap">
								<div class="m-0 p-0 d-flex align-items-center">
									<template v-if="scope.row.person_image.length > 0">
										&nbsp;&nbsp;&nbsp;住人撤离图片
										<viewer style="padding: 10px;">
											<img style="width: 45px;height: 45px;margin:0 5px;"
												:src="scope.row.person_image">
										</viewer>
									</template>
								</div>
								<div class="m-0 p-0 d-flex align-items-center ml-3">
									<template v-if="scope.row.escape_image.length > 0">
										开设逃生通道：
										<viewer style="padding: 10px;">
											<img style="width: 45px;height: 45px;margin:0 5px;"
												:src="scope.row.escape_image">
										</viewer>
									</template>
								</div>
								<div v-if="scope.row.separate_image.length > 0"
									class="m-0 p-0 d-flex align-items-center ml-3">
									落实防火分隔：
									<viewer style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.separate_image">
									</viewer>
								</div>
								<div v-if="scope.row.smoke_image.length > 0"
									class="m-0 p-0 d-flex align-items-center ml-3">安装独立烟感：
									<viewer style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.smoke_image">
									</viewer>
								</div>
								<div v-if="scope.row.defense_image.length > 0"
									class="m-0 p-0 d-flex align-items-center ml-3">配备防毒面罩：
									<viewer style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.defense_image">
									</viewer>
								</div>
								<div v-if="scope.row.train_image.length > 0"
									class="m-0 p-0 d-flex align-items-center ml-3">开展宣传培训：
									<viewer style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.train_image">
									</viewer>
								</div>
								<div v-if="scope.row.circuit_image.length > 0"
									class="m-0 p-0 d-flex align-items-center ml-3">电器线路套管铺设图片：
									<viewer style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.circuit_image">
									</viewer>
								</div>
								<div v-if="scope.row.knife_image.length > 0"
									class="m-0 p-0 d-flex align-items-center ml-3">无使用闸刀开关图片：
									<viewer style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.knife_image">
									</viewer>
								</div>
								<div v-if="scope.row.electrocar_image.length > 0"
									class="m-0 p-0 d-flex align-items-center ml-3">违规存放电动车图片：
									<viewer style="padding: 10px;">
										<img style="width: 45px;height: 45px;margin:0 5px;"
											:src="scope.row.electrocar_image">
									</viewer>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column width="230" align="center" label="操作人员">
					<template slot-scope="scope">
						<template v-if="scope.row.edit_user_type == 10">
							<span 
								style="font-weight: 800;">{{scope.row.edit_reseau_user.real_name}}
								{{scope.row.edit_reseau_user.mobile}}</span>
						</template>
						<template v-if="scope.row.edit_user_type == 20">
							<span
								style="font-weight: 800;"><br>{{scope.row.edit_unit_user.real_name}}
								{{scope.row.edit_unit_user.mobile}}</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="场所名称">
					<template slot-scope="scope">
						<span>{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="150" label="预警分级">
					<template slot-scope="scope">
						<div style="width: 100%;" class="d-flex justify-content-center align-items-center">
							<div v-if="scope.row.early_warning == 10"
								style="width: 80px;height: 30px;background-color: rgb(108, 189, 40);line-height: 30px;font-weight: 800;color:#fff;">
								良好
							</div>
							<div v-if="scope.row.early_warning == 20"
								style="width: 80px;height: 30px;background-color: rgb(50,180,237);line-height: 30px;font-weight: 800;color:#fff;">
								一般
							</div>
							<div v-if="scope.row.early_warning == 30"
								style="width: 80px;height: 30px;background-color: rgb(250, 186, 38);line-height: 30px;font-weight: 800;color:#fff;">
								危险
							</div>
							<div v-if="scope.row.early_warning == 40"
								style="width: 80px;height: 30px;background-color: rgb(195,5,33);line-height: 30px;font-weight: 800;color:#fff;">
								高危
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center" width="150" label="是否住人">
					<template slot-scope="scope">
						<span v-if="scope.row.is_person == 10">否</span>
						<span v-else style="color: red;">是</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="150" label="开设逃生通道">
					<template slot-scope="scope">
						<span v-if="scope.row.is_escape == 10">否</span>
						<span v-else style="color: red;">是</span><br>
					</template>
				</el-table-column>
				<el-table-column align="center" width="120" label="落实防火分隔">
					<template slot-scope="scope">
						<span v-if="scope.row.is_separate == 10">否</span>
						<span v-else style="color: red;">是</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="120" label="安装独立烟感">
					<template slot-scope="scope">
						<span v-if="scope.row.is_smoke == 10">否</span>
						<span v-else style="color: red;">是</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="120" label="配备防毒面罩">
					<template slot-scope="scope">
						<span v-if="scope.row.is_defense == 10">否</span>
						<span v-else style="color: red;">是</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="120" label="开展宣传培训">
					<template slot-scope="scope">
						<span v-if="scope.row.is_train == 10">否</span>
						<span v-else style="color: red;">是</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="170" label="电器线路套管铺设">
					<template slot-scope="scope">
						<span v-if="scope.row.is_circuit == 10">否</span>
						<span v-else style="color: red;">是</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="160" label="闸刀开关使用">
					<template slot-scope="scope">
						<span v-if="scope.row.is_knife == 10">否</span>
						<span v-else style="color: red;">是</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="160" label="没有违规存放电动车">
					<template slot-scope="scope">
						<span v-if="scope.row.is_electrocar == 10">否</span>
						<span v-else style="color: red;">是</span>
					</template>
				</el-table-column>
				<el-table-column align="center" width="150" label="时间">
					<template slot-scope="scope">
						<span>{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="logLists.per_page" style="display: inline-block;float: right;" background
					@current-change="logPageChange" layout="prev, pager, next" :total="logLists.total">
				</el-pagination>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import appMap from '@/components/app-map.vue';
	export default {
		inject: ['layout'],
		components: {
			appMap,
		},
		data() {
			return {
				latitude: "",
				longitude: "",
				address: "",
				lists: [],
				firstList: [],
				secondList: [],
				thirdList: [],
				reseauList: [],
				search: {
					s: 'store/reseau.check/index',
					early_warning: 0,
					keyword: '',
					page: 1,
					first_id: 0,
					second_id: 0,
					third_id: 0,
					reseau_id: 0,
				},
				logSearch: {
					s: 'store/reseau.check/checkLog',
					reseau_check_id: 0,
					keyword: '',
					page: 1,
				},
				dialogVisible: false,
				logLists: [],
			}
		},
		created() {
			this.getList();
			this.getReseauList();
			this.getUnitList();
		},
		methods: {
			handleExport() {
				let params = {
					s: 'store/reseau.check/export',
					early_warning: this.search.early_warning,
					keyword: this.search.keyword,
					first_id: this.search.first_id,
					second_id: this.search.second_id,
					third_id: this.search.third_id,
					reseau_id: this.search.reseau_id,
				};
				this.axios({
					token: true,
					params: params,
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						window.location.href = this.$conf.base_url + 'check_list.xls'
					}
				}).catch(err => {})
			},
			handleFirstChange(first_id) {
				this.search.second_id = 0;
				this.search.third_id = 0;
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
					}
				}
				this.getReseauList();
			},
			handleSecondChange(second_id) {
				this.search.third_id = 0;
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
					}
				}
				this.getReseauList();
			},
			handleThirdChange(third_id){
				this.getReseauList();
			},
			getUnitList() {
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.firstList = res.data.data

				}).catch(err => {});
			},
			getReseauList() {
				this.axios({
					token: true,
					params: {
						s: 'store/reseau.check/reseauLists',
						first_id: this.search.first_id,
						second_id: this.search.second_id,
						third_id: this.search.third_id,
					},
					method: 'get',
				}).then(res => {
					this.reseauList = res.data.data
				}).catch(err => {});
			},
			earlyWarning(early_warning) {
				this.search.early_warning = early_warning
				this.toSearch()
			},
			logPageChange(page) {
				this.logSearch.page = page;
				this.getLogList();
			},
			openDialog(data) {
				this.dialogVisible = true;
				this.logSearch.reseau_check_id = data.reseau_check_id;
				this.getLogList();
			},
			getLogList() {
				this.axios({
					token: true,
					params: this.logSearch,
					method: 'get',
				}).then(res => {
					this.logLists = res.data.data
				}).catch(err => {})
			},
			showMap(item) {
				this.latitude = item.latitude;
				this.longitude = item.longitude;
				this.address = item.address;
				this.$refs.appMap.dialogVisible = !this.$refs.appMap.dialogVisible
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该排查吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/reseau.check/delete',
							reseau_check_id: data.reseau_check_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>
